{/* In Material UI 5 wird die Emotion Library als Standard-Style-Engine verwendet². Um die Funktion mit der Emotion Library zu ersetzen, 
können Sie die `styled`-Funktion von Emotion verwenden². 
Hier ist ein Beispiel: */}


import React from 'react';
// import { styled } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';


// const Root = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   border: 10,
//   borderColor: theme.palette.primary,
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: theme.palette.background.paper,
//   padding: theme.spacing(1),
// }));

// const ButtonIcon = styled(Button)(({ theme }) => ({
//   // backgroundColor: theme.palette.primary.main,
//   // color: theme.palette.primary.contrastText, // wech = besser!!!
//   height: theme.spacing(3),
//   // width: theme.spacing(1),
//   color: theme.palette.common.black
// })) as typeof Box;


import { SvgIcon } from '@mui/material';



export function SvgCaretUpIcon() {
    return (
        <SvgIcon>
        {/* credit: plus icon from https://heroicons.com/ */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            {/*! <--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/>
        </svg>
        </SvgIcon>
    );
}
  
export function SvgCaretDownIcon() {
    return (
        <SvgIcon>
        {/* credit: plus icon from https://heroicons.com/ */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
            <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/>
        </svg>  
        </SvgIcon>
    );
}

/*
 * Zwei IconButtons zu einer Einheit übereinander stapeln
 * incr/decr: parameterlose Pfeilfunktionen, die bei Klick auf den oberen/unteren Button aufgerufen werden.
 * upDisabled/downDisabled: Boolesche Ausdrücke, die bestimmen , ob der obere/untere Button aus disabled gesetzt werden soll.
*/
function IconButtonStack({ incr, decr, downDisabled, upDisabled } 
    : {incr: () => void, decr: () => void, downDisabled: boolean, upDisabled: boolean}) {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <IconButton sx={{padding: 0}}
        disabled={upDisabled}
        onClick={incr}
      >
        <SvgCaretUpIcon/>
      </IconButton>
    {/*Es braucht hier einfach einen Scheiß Abstand zwischen den Buttons!!!!!  */}
      <IconButton sx={{padding: 0 }}
        disabled={downDisabled}          
        onClick={decr}        
        >
        <SvgCaretDownIcon/>
      </IconButton>    
    </Box>    
  );
}


{ /* const demoValues = ['Value 1', 'Value 2', 'Value 3', 'Value 4', 'Value 5']; */}
// Ist eine Komponente, die ein nur lesbares Textfeld mit zwei übereinandergestapelten Buttons, wobei der eine ein Dreieck aufweist,
// das nach oben zeigt und der andere ein Dreieck aufweist, das nach unten zeigt. Der obere Button dient zum inkrementieren innerhalb
// einer Liste von Werten `values', der untere zum Dekrementieren innerhalb einer Liste von festen Werten. 
// Der ausgewählte Wert wird im Textfeld 
// angezeigt. Wenn die Komponente das erste Mal vor einer erfolgten Benutzerauswahl angezeigt wird, 
// wird der erste Wert in der Liste angezeigt. Wird der erste Wert in
// der Liste angezeigt, ist der untere Button deaktiviert. Wird der letzte Wert in der Liste angezeigt, ist der obere Button 
// deaktiviert.
// Mit dem Parameter `styleLocal' können der Komponente zusätzliche CSS-Styles übergeben werden, z. b. um einen Farbverlauf
// in der Darstellung der Komponente zu realisieren.
export function ValuePicker({values,styleLocal}:{values: Array<string> | Array<number>, styleLocal?:React.CSSProperties}) {
  const [valueIndex, setValueIndex] = React.useState(0);

  const handleNextClick = () => {
    if (valueIndex < values.length - 1) {
      setValueIndex(valueIndex + 1);

      let vi=valueIndex
      { console.log(`ButtonUp/NextClick: valueIndex/value after increment: ${vi}/${values[vi]}`) }
    }
  };

  const handlePrevClick = () => {
    if (valueIndex > 0) {
      setValueIndex(valueIndex - 1);

      let vi=valueIndex
      { console.log(`ButtonUp/PrevClick: valueIndex/value after decrement: ${vi}/${values[vi]}`) }

    }
  };
  

  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',
              border: 1, borderRadius: '3%', 
              borderColor: (theme) => theme.palette.primary.light, 
              // borderRadius: (theme) => theme.shape.borderRadius,
              ml: '10px'}} style={styleLocal}> 
      <Box sx={{padding: '10px', minWidth: 100}}>{values[valueIndex]}</Box> {/* das Textfeld */}
      <IconButtonStack // die gestapelten Buttons zum Incr/Decr
        incr={handleNextClick} decr={handlePrevClick} 
        downDisabled={valueIndex === 0} upDisabled={valueIndex === values.length - 1}
      />   
    </Box>
  );
}
