import LocalPizzaTwoToneIcon from '@mui/icons-material/LocalPizzaTwoTone';
import { Box } from '@mui/material';

{/* Die Topleiste mit dem Pizza-Icon + Text + Auswahl + Einloggen-Button */}
export function TopBar() {
 return (
    <Box sx={{ backgroundColor: '#d6d6d6', display: 'flex', padding: '10px', 
            justifyContent: 'space-between' }}>    
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <LocalPizzaTwoToneIcon />
            PizzaForever-App
        </Box>                 
    </Box>    
 );

}