import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import { OrderItem, PizzaData, PizzaMenuData, PizzaSize, Topping, ORDER_FACTORY } from './value-types'

import { AddOrChangeToCartDialog } from './add-or-change-to-cart'

function OrderTableRow({ rowData, allToppings, handleAddChangedItemToCart } 
    : {rowData : PizzaData, allToppings: Topping[], handleAddChangedItemToCart: (modifiedItem: OrderItem) => void }) { 
    {                           
        return(        
            <TableRow>
                <TableCell>         
                    <AddOrChangeToCartDialog 
                        allToppings = { allToppings }
                        item = { ORDER_FACTORY.createInitialOrderItem(rowData,PizzaSize.SMALL)}
                        callButtonProps={{ labelText: rowData.name }} 
                        handleAddChangedItemToCart = {handleAddChangedItemToCart}
                    />
                </TableCell>            
                <TableCell>
                    <AddOrChangeToCartDialog 
                        allToppings = { allToppings }
                        item = { ORDER_FACTORY.createInitialOrderItem(rowData,PizzaSize.SMALL)}
                        callButtonProps={{ labelText: rowData.priceSmall.toFixed(2)}}
                        handleAddChangedItemToCart = {handleAddChangedItemToCart}
                    />
                </TableCell>
                <TableCell>
                    <AddOrChangeToCartDialog 
                        allToppings = { allToppings }
                        item = { ORDER_FACTORY.createInitialOrderItem(rowData,PizzaSize.MEDIUM)}
                        callButtonProps={{ labelText: rowData.priceMedium.toFixed(2) }} 
                        handleAddChangedItemToCart = {handleAddChangedItemToCart}
                    />
                </TableCell>
                <TableCell>
                    <AddOrChangeToCartDialog 
                        allToppings = { allToppings }
                        item = { ORDER_FACTORY.createInitialOrderItem(rowData,PizzaSize.LARGE)}
                        callButtonProps={{ labelText: rowData.priceLarge.toFixed(2) }} 
                        handleAddChangedItemToCart = {handleAddChangedItemToCart}
                    />
                </TableCell>
            </TableRow>

        );
    }
}

// ZE: *Order*Table, OrderTableRow: subopti Benamsung, im Server heißt das Ding Menu & Menu ist auch das korrekte engl. Wort für Speisekarte!

/**
 * Zeigt die Speisekarte an
 * @param param0 
 * @returns 
 */
export default function OrderTable({tableData, toppings, handleAddChangedItemToCart} 
    : {tableData : PizzaMenuData, toppings: Topping[], handleAddChangedItemToCart: (modifiedItem: OrderItem) => void}) {    
    return (
        tableData.length !== 0 ?
        <TableContainer>
            <Table sx={{tableLayout: 'auto'}}>
                <TableHead>
                    <TableRow>                        
                        <TableCell><b>Pizza</b></TableCell>
                        <TableCell><b>{PizzaSize.SMALL}</b></TableCell>
                        <TableCell><b>{PizzaSize.MEDIUM}</b></TableCell>
                        <TableCell><b>{PizzaSize.LARGE}</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map( (rowData : PizzaData) => 
                            <OrderTableRow 
                                key = {Number(rowData.id)} 
                                rowData = {rowData} 
                                allToppings={toppings} 
                                handleAddChangedItemToCart = {handleAddChangedItemToCart}
                            />
                        )}
                </TableBody>
            </Table>
        </TableContainer>
        : <TableContainer>Lade Speisekarte...</TableContainer>
    ); 
}