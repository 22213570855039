{/* Ein Item für den Warenkorb konfigurieren - Nach Auswahl idSpeisenkarte oder nach Aufruf "ändern" im Warenkorb */}

// ACHTUNG: TODOs!!!

// TODO: TESTS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import { useState } from 'react';
import { useImmer } from 'use-immer';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete, TextField, 
    FormGroup, 
    FormControlLabel,
    IconButton, 
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
    Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';    
import { Topping, PizzaSize, OrderItem } from "./value-types";
import Checkbox from '@mui/material/Checkbox';

import { SvgCaretDownIcon } from './ValuePicker'

enum ToppingListExpansion{COLLAPSED,EXPANDED};

function ToppingFormGroup({toppings,toppingSelection,handleToppingCheckBoxSelectClick,listExpanded} : {
    toppings:Topping[],
    toppingSelection:Set<Topping>,
    handleToppingCheckBoxSelectClick:(event: React.ChangeEvent<HTMLInputElement>) => void,
    listExpanded:ToppingListExpansion } ) {

    let NUM_SHOW=0;
    if(listExpanded===ToppingListExpansion.EXPANDED)
        NUM_SHOW=toppings.length;
    else
        NUM_SHOW=3;
    return (
        toppings.length > 0 ?
            <FormGroup> { // ZE: Alternative mit for-Schleife: react.dev/LEARN/kepping-components-pure#Local Mutation: Your component's ...
                toppings.filter(x=>toppings.indexOf(x) < NUM_SHOW).map(
                    topping => 
                    <FormControlLabel 
                        key={Number(topping.id)} 
                        control={
                            <Checkbox id={`${topping.id}`}
                            sx={{
                                color: grey[800],
                                '&.Mui-checked': {
                                  color: grey[600],
                                },
                            }}
                                key={Number(topping.id)}
                                checked={ toppingSelection.has(topping) }
                                onChange={ handleToppingCheckBoxSelectClick } 
                            />
                        }
                        label={`${topping.name} (+${topping.price.toFixed(2)} €)`}
                    />)
                }
            </FormGroup>    
        : <Box>Extrasliste ist leer!</Box>
    )
}

function ExpansionButton({ listExpanded, setListExpanded } : 
    {listExpanded:ToppingListExpansion,
    setListExpanded: (value: React.SetStateAction<ToppingListExpansion>) => void
    }) {
    return (
        listExpanded===ToppingListExpansion.COLLAPSED?
        <Button sx={{ textTransform: 'none', color:'black' }} 
            endIcon={ <SvgCaretDownIcon/> }
            onClick={ ()=>setListExpanded(ToppingListExpansion.EXPANDED) }
        >
            Sämtliche Extras anzeigen
        </Button> 
        : null
    )
}

export function ToppingList({toppings,toppingSelection,handleToppingCheckBoxSelectClick}:
    {toppings:Topping[],
    toppingSelection:Set<Topping>,
    handleToppingCheckBoxSelectClick:(event: React.ChangeEvent<HTMLInputElement>) => void
    }) { 

    const [listExpanded,setListExpanded] = useState(ToppingListExpansion.COLLAPSED);    
    
    return (
        <Box>      
            <ToppingFormGroup
                toppings={toppings} 
                toppingSelection={toppingSelection}
                handleToppingCheckBoxSelectClick={handleToppingCheckBoxSelectClick}
                listExpanded={listExpanded}
            />
            <ExpansionButton 
                listExpanded={listExpanded}
                setListExpanded={setListExpanded}
            />            
        </Box>
    )
}

export type CallButtonProps= { labelText: string, underlined?: boolean, endIcon?: JSX.Element}

export function AddOrChangeToCartDialog(
    { item, allToppings, callButtonProps, handleAddChangedItemToCart } 
    : { item: OrderItem, allToppings: Topping[], callButtonProps: CallButtonProps, 
        handleAddChangedItemToCart:(modifiedItem: OrderItem) => void }) {

        
    const [open, setOpen] = useState(false); 

    const [currentConfig, updateConfig] = useImmer(item.pizzaConfig);

    function handleChangeQuantityEvent(
        event: React.SyntheticEvent, 
        value: string, 
        reason: AutocompleteChangeReason, 
        details?: AutocompleteChangeDetails<string>) {            
        updateConfig(draft => {
            draft.quantity = BigInt(value);            
        })        
    }

    function handleChangeSizeEvent(event: React.SyntheticEvent, value: PizzaSize, reason: AutocompleteChangeReason, 
            details?: AutocompleteChangeDetails<PizzaSize>) {                        
        updateConfig(draft => { 
            draft.pizzaSize = value 
        });
    }

    function handleToppingCheckBoxSelectClick(e:React.ChangeEvent<HTMLInputElement>) {     
        updateConfig(draft => {
            if(allToppings.length === 0) return
            const topping = allToppings.find( top =>  BigInt(e.target.id) === top.id )
            if(topping === undefined ) 
                throw new Error("Gartenzaun!")            
            if(e.target.checked) {
                draft.addTopping(topping)
            } else
               draft.deleteTopping(topping)
        })
    }
    
    function handleChangeNoteContent(e: React.ChangeEvent<HTMLInputElement>) {
         updateConfig( draft => { draft.notice = e.target.value } )
    }

    return (
        <div>
            {(!open) ?
                <Button onClick={() => setOpen(true)} sx={{textTransform: 'none', color:'black'}} endIcon={callButtonProps.endIcon}>
                    { callButtonProps.underlined ? <u>{callButtonProps.labelText}</u> : `${callButtonProps.labelText}`}
                </Button>        
            :
            <>
                {/* statt Button: Label!!!*/}
                <Typography sx={{color:'black'}}>{ callButtonProps.labelText }</Typography>                
                <Dialog open={open} > 
                    <Box sx={{ backgroundColor: '#d6d6d6', display: 'flex', 
                            justifyContent: 'space-between' }}>
                        <DialogTitle>Extras & Anmerkungen</DialogTitle>
                        <IconButton onClick={() => { setOpen(false); updateConfig(item.pizzaConfig); } }>
                            <CloseIcon/>
                        </IconButton>
                    </Box>                
                    <DialogContent>                    
                        <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
                            <DialogContentText>{item.pizza.name}</DialogContentText>
                            <DialogContentText>
                                {`${ new OrderItem(item.id, item.pizza,currentConfig).pizzaPriceTimesQuantity().toFixed(2)} €`}
                            </DialogContentText>
                        </Box>
                        <Box sx={{p:1}} />
                        <Autocomplete      // Auswahl Anzahl                
                            options={["1","2","3","4","5"]}                         
                            defaultValue={ `${item.pizzaConfig.quantity}` } 
                            disableClearable={true}
                            onChange={handleChangeQuantityEvent}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    sx={{caretColor:"transparent"}}                                
                                    onKeyDown={e=>{e.preventDefault(); return false;}}
                                    onCut={e=>e.preventDefault()}
                                    onPaste={e=>e.preventDefault()}
                                    label=""
                                /> 
                            }
                        />
                        <Box sx={{p:1}} />
                        <Autocomplete    // Auswahl Größe  
                            options={[PizzaSize.SMALL,PizzaSize.MEDIUM,PizzaSize.LARGE]}                         
                            defaultValue={item.pizzaConfig.pizzaSize}
                            disableClearable={true}
                            onChange={handleChangeSizeEvent}                        
                            renderInput={(params) => 
                                <TextField {...params} 
                                    sx={{caretColor:"transparent"}}
                                    onKeyDown={e=>{e.preventDefault(); return false;}}
                                    onCut={e=>e.preventDefault()}
                                    onPaste={e=>e.preventDefault()}
                                    label=""/> }
                        />            
                        <DialogContentText variant="caption" sx={{pt:1}}>
                            Extras auswählen:
                        </DialogContentText>
                        <ToppingList
                            toppings={allToppings}
                            toppingSelection={ currentConfig.toppings }
                            handleToppingCheckBoxSelectClick={handleToppingCheckBoxSelectClick}/>
                        <TextField
                            defaultValue={item.pizzaConfig.notice}
                            margin="normal"
                            id="Anmerkungen"
                            label="Anmerkungen"
                            multiline
                            rows={2}
                            fullWidth={true}
                            InputProps={{spellCheck: false}}
                            onChange={handleChangeNoteContent}
                        />
                    </DialogContent>                
                    <DialogActions>
                        <Button variant="contained" sx={{backgroundColor:'black' }} 
                            onClick = { () => {
                                    setOpen(false); 
                                    handleAddChangedItemToCart(new OrderItem(item.id,item.pizza,currentConfig))
                                }
                            }
                        > {/*sx={{textTransform: 'none'}}*/}
                            {`In den Warenkorb (${new OrderItem(item.id,item.pizza,currentConfig).billTotal().toFixed(2)} €)`}
                        </Button>
                    </DialogActions>    
                </Dialog>
            </>
    }
        </div>
    );
}