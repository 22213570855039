/**
 * Ist ein typsicherer Wrapper für fetch. Evtl. wird es auch eine komplette Zugriffsschicht.
 * ACHTUNG: Der Ansatz ist MÜLL!!!!! Wenn ich req.get<MenuData> aufrufe, dann will ich MenuData und zwar korrekt mit allem was drin steckt.
 * Das ist nicht der Fall!!! => Erfüllt seinen zweck nicht!!!
 * @param url URL, von der geholt werden soll.
 * @param config Konfigurationsparameter
 * @returns 
 * @see https://www.newline.co/@bespoyasov/how-to-use-fetch-with-typescript--a81ac257
 */
async function req<TResponse>(
    url: string, 
    config: RequestInit
): Promise<TResponse> {
    const response = await fetch(url, config);
    if(!response.ok) {
        throw new Error(`Response not OK: ${response.status}`)
    }
    return await response.json() as TResponse;
}

export const request = {
    get: <TResponse>(url: string) => 
        req<TResponse>(url,{ method: 'GET' }),

    // Using `extends` to set a type constraint:
    post: <TBody extends BodyInit, TResponse>(url: string, body: TBody) => 
        req<TResponse>(url, { method: 'POST', body }),

    // ... weitere noch reinzuhacken

    
}



