import { Box, Button, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CartData, OrderItem, Topping } from './value-types';
import Big from 'big.js';
import { AddOrChangeToCartDialog } from './add-or-change-to-cart';

const margLeft='15px'
const margRight='20px' // Einrückung für rechtsbündige Geldbeträge

// ZE: allToppings in der Schnittstelle ist häßlich.
function OrderItemView(
    {
        item, 
        handleReplaceChangedOrderItemInCart, 
        handleDeleteOrderItemInCart, 
        allToppings 
    } : { 
        item: OrderItem, 
        handleReplaceChangedOrderItemInCart: (modifiedItem: OrderItem) => void, 
        handleDeleteOrderItemInCart: (selectedItem: OrderItem) => void, 
        allToppings: Topping[] }) {
    return (
        <Box sx={{     
            width:'35ch',
            pb:'20px', pt: '20px',
            borderBottom: '1px solid black'
        }}>       
            <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: '0', pb: '0' }}>    
                <Box>
                    <Typography variant='body1'><b>{`${item.pizzaConfig.quantity}x ${item.pizza.name}`}</b></Typography>                    
                </Box>
                <Button 
                    variant="text" 
                    sx={{pt: '0', pb: '0'}} 
                    endIcon={<DeleteIcon sx={{color:'black' }}
                    onClick = { () => handleDeleteOrderItemInCart(item) }
                />}>
                    <Typography variant='body1' sx={{ color:'black' }}>
                    {`${item.pizzaPriceTimesQuantity().toFixed(2)} €`}
                    </Typography>
                </Button>
            </Box>
            <Typography variant='body2'>
                <Box sx={{textAlign:'left', ml: margLeft}}>
                    {`- ${item.pizzaConfig.pizzaSize}`}
                </Box>            
                { Array.from(item.pizzaConfig.toppings).map(
                        topping => 
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: margLeft }}>
                                <Box>{`+ ${topping.name}`}</Box>
                                <Box sx={{mr:margRight}}>
                                        {`${topping.price.mul(Number(item.pizzaConfig.quantity)).toFixed(2)} €`}
                                </Box>
                            </Box>
                    )
                }        
                { item.pizzaConfig.notice ? 
                    <Box sx={{textAlign:'left', ml: margLeft, width: '30ch'}}>
                        <i>
                            { `- ${item.pizzaConfig.notice}`}
                        </i>
                    </Box> 
                    :null 
                }
                <Box sx={{ display: 'flex', justifyContent: 'space-between', ml: margLeft }}>
                    <AddOrChangeToCartDialog 
                        allToppings = { allToppings }
                        item = { item }
                        callButtonProps={{ 
                            labelText: 'Artikel bearbeiten', 
                            endIcon: <ModeEditOutlineIcon sx={{color:'black'}}/>, 
                            underlined: true 
                        }} 
                        handleAddChangedItemToCart = { handleReplaceChangedOrderItemInCart }    
                    />
                    <Box sx={{mr: margRight, borderTop: '0.5px dotted grey' }}>
                        {`${item.billTotal().toFixed(2)} €`}
                    </Box>
                </Box>     
            </Typography>                  
        </Box>
    )
}


// ZE: null idTypdef. TILGEN !!!!
export function CartView({cartData, handleReplaceChangedOrderItemInCart, handleDeleteOrderItemInCart, allToppings } 
    : {
        cartData: CartData, 
        handleReplaceChangedOrderItemInCart: (modifiedItem: OrderItem) => void, 
        handleDeleteOrderItemInCart: (selectedItem: OrderItem) => void, 
        allToppings: Topping[] }) {
    return (
        <Box>
            <Box sx={{ backgroundColor: '#d6d6d6', padding: '10px', mt:'5px', textAlign:'center', display:'flex'}}>
                <ShoppingCartIcon sx={{verticalAlign:'center'}}/>
                <Box sx={{margin:'auto'}}><b>Warenkorb</b></Box>
            </Box>            
            {cartData !== null && cartData.cartItems.length > 0 ?
                cartData.cartItems.map( item => 
                    <OrderItemView 
                        item={item} 
                        handleReplaceChangedOrderItemInCart = { handleReplaceChangedOrderItemInCart }
                        handleDeleteOrderItemInCart = { handleDeleteOrderItemInCart }
                        allToppings = { allToppings }
                    />
                ) 
             : <Box sx={{p:'10px'}}>Der Warenkorb ist leer.</Box>
             }
             { cartData.cartItems.length > 0 ?
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>Bestellwert:</Box>                
                    <Box sx={{mr: margRight}}>
                        {`${cartData?.cartItems.map(item => item.billTotal())
                            .reduce((acc:Big, currentValue:Big) => acc.add(currentValue), new Big(0)).toFixed(2) } €`}
                    </Box>
                </Box>   
            : null}
        </Box>
    )
}