/** 
 * DatenTypen für den Datenaustausch im PizzaService = Value Objects.
*/

import Big from 'big.js';

import {immerable} from 'immer'

export enum PizzaSize{ SMALL = "klein", MEDIUM = "mittel", LARGE = "groß" };

export class Topping {

    #id: bigint; 
    #name: string; 
    #price: Big

    constructor(id: bigint, name: string, price: Big) {
        this.#id=id;
        this.#name=name;
        this.#price=Big(price);
    }

    get id() { return this.#id }

    get name() { return this.#name }

    get price() { return this.#price }

}

/** 
 * Immutable halten.
*/
export class PizzaData  {

    #id: bigint; 
    #name: string; 
    #priceLarge: Big; 
    #priceMedium: Big; 
    #priceSmall: Big    
     
    constructor(id: bigint, name: string, priceLarge: Big, priceMedium: Big, priceSmall: Big){
        this.#id=id; 
        this.#name=name; 
        this.#priceLarge=Big(priceLarge); 
        this.#priceMedium=Big(priceMedium), 
        this.#priceSmall=Big(priceSmall);
    }
 
    get id() { return this.#id}

    get name() { return this.#name }

    get priceLarge() { return this.#priceLarge }

    get priceMedium() { return this.#priceMedium }

    get priceSmall() { return this.#priceSmall }

    priceForSize(size: PizzaSize) : Big  {
        let currentPizzaPrice:Big=Big(0);    
        switch(size) {
            case PizzaSize.SMALL:
                currentPizzaPrice = this.priceSmall;
                break;
            case PizzaSize.MEDIUM:
                currentPizzaPrice = this.priceMedium;
                break;
            case PizzaSize.LARGE:
                currentPizzaPrice = this.priceLarge;            
        }    
        return currentPizzaPrice;
    } 
}

export type PizzaMenuData = PizzaData[];
export type MenuData = { pizzas: PizzaMenuData }

/** 
 * ZE ZE ZE: Hier fehlt die Unveränderlichkeit - Wird nur im Immer gebraucht ~ Wie funktioniert Immer?
*/
export class PizzaConfiguration {    
    #quantity: bigint;
    #pizzaSize: PizzaSize;
    #toppings: Set<Topping>; // Topping.id
    #notice: string

    [immerable] = true
    
    constructor(
        quantity: bigint,
        pizzaSize: PizzaSize,
        toppings: Set<Topping>, // Topping.id
        notice: string
    ) {
        this.#quantity=quantity
        this.#pizzaSize=pizzaSize
        this.#toppings=new Set<Topping>(toppings)
        this.#notice=notice
    }

    get quantity() { return this.#quantity }

    get pizzaSize() { return this.#pizzaSize }

    get toppings() { return new Set<Topping>(this.#toppings) }

    addTopping(topping: Topping) {
        this.#toppings.add(topping)
    }

    deleteTopping(topping: Topping) {
        this.#toppings.delete(topping)
    }

    get notice() { return this.#notice }

    set quantity(quantity: bigint) { this.#quantity=quantity; }

    set pizzaSize(pizzaSize: PizzaSize) { this.#pizzaSize=pizzaSize; }

    set notice(notice: string) { this.#notice=notice }

}


/** 
 * Immutable halten.
*/
export class OrderItem {
    #id: bigint
    #selectedPizza: PizzaData;
    #pizzaConfig: PizzaConfiguration;

    constructor(id: bigint, selectedPizza: PizzaData, pizzaConfig: PizzaConfiguration ) {
        this.#id=id
        this.#selectedPizza = selectedPizza
        this.#pizzaConfig = pizzaConfig
    }

    get id() { return this.#id }

    get pizza() { return this.#selectedPizza }

    get pizzaConfig() { return this.#pizzaConfig }

    set pizzaConfig(config: PizzaConfiguration) { this.#pizzaConfig = config }

    /**
     * Erzeuge ein initiales OrderItem für eine bestimmte Pizza und Größe mit Defaultwerten für alle anderen Optionen.
     * @param pizzaData Daten der ausgewählten Pizza.
     * @param pizzaSize Größe der ausgewählten Pizza.
     * @returns 
     */
    // static createInitialOrderItem(pizzaData: PizzaData, pizzaSize: PizzaSize ) {
    //     return new OrderItem(BigInt(0), pizzaData,
    //         new PizzaConfiguration(BigInt(1),pizzaSize,new Set<Topping>(),"") )
    // }

    /**
     * @returns Preis der gegenwärtig ausgewählten Pizza bei der ausgewählten Größe mal ausgewählter Anzahl.
     */
    pizzaPriceTimesQuantity() {
        const currentPizzaPrice:Big=this.pizza.priceForSize(this.pizzaConfig.pizzaSize); 
        console.log(`currentPizzaPrice: ${currentPizzaPrice}, pizzaConfig.quantity: ${this.pizzaConfig.quantity}, pizzaConfig.size: ${this.pizzaConfig.pizzaSize}`)
        // ZE ZE ZE: TEST SCHWACHSTELLE: bleiben die Integerwerte exakt nach der Konversion zu number??? 
        // Big.js zwingt mich zu diesem MÜLL!!!!       
        const quantityNum = Number(this.pizzaConfig.quantity);        
        const result=currentPizzaPrice.mul(quantityNum);
        console.log(`result: ${result}`)
        return result;
    }    

    /**
     * 
     * Gesamtpreis der gegenwärtigen Auswahl: Pizza, Größe, Anzahl, Extras
     * @param size 
     * @returns 
     */
    billTotal(): Big {
        const currentToppingsTotal = Array.from(this.pizzaConfig.toppings)
            .map(topping => topping.price)
            .reduce((acc:Big, currentValue:Big) => acc.add(currentValue), new Big(0));        
        // ZE ZE ZE: TEST SCHWACHSTELLE: bleiben die Integerwerte exakt nach der Konversion zu number??? 
        // Big.js zwingt mich zu diesem MÜLL!!!!       
        const quantityNum = Number(this.pizzaConfig.quantity);
        const currentPizzaPrice:Big = this.#selectedPizza.priceForSize(this.#pizzaConfig.pizzaSize); 
        const result = (currentPizzaPrice.plus(Number(currentToppingsTotal))).times(quantityNum);        
        return result;
    }    
}

class OrderItemFactory {
    #nextItemId = BigInt(0)

    /**
     * Erzeuge ein initiales OrderItem für eine bestimmte Pizza und Größe mit Defaultwerten für alle anderen Optionen.
     * @param pizzaData Daten der ausgewählten Pizza.
     * @param pizzaSize Größe der ausgewählten Pizza.
     * @returns 
     */
    createInitialOrderItem(pizzaData: PizzaData, pizzaSize: PizzaSize) {
        return new OrderItem(this.#nextItemId++, pizzaData, new PizzaConfiguration(BigInt(1), pizzaSize, new Set<Topping>(),""))
    }
}

export const ORDER_FACTORY = new OrderItemFactory()

/**
 * Repräsentiert die Daten für einen Einkaufswagen. Dabei wird davon ausgegangen, daß es für eine Pizza - kundenfreundlich - 
 * mehrere Positionen im Einkaufswagen geben kann. Z. B. damit die selbe Pizza mit unterschiedlichen Beilagen geordert werden kann.
 * 
 * 
 */

export class CartData {

    [immerable] = true

    #cartItems: Array<OrderItem>

    constructor() {
        this.#cartItems=new Array<OrderItem>();
    }
    addItem(item: OrderItem) {
        this.#cartItems.push(item)
    }

    get cartItems() {
        return this.#cartItems
    }

    
}